import mime from 'mime-types';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { rekaFactory } from '@/api/reka';
import { useAppConfig } from '@/store/hooks/use-app-config';
import { useAuthStore } from '@/store/hooks/use-auth-store';
import { useRequestOptionStore } from '@/store/hooks/use-request-option';
import { IRequestOptionState } from '@/store/slices/request-option-slice';
import { UploadedFile } from '@/store/slices/uploaded-files-slice';
import { Chat } from '@/utils/types';
import { UrlObjectManager } from '@/utils/url-object-manager';
import { useDisclosure } from '@chakra-ui/react';

export type ChatModes = null | 'pdf' | 'perception' | 'speech2speech' | 'agent';

const ChatControlsContext = React.createContext<{
    input: string;
    setInput: (str: string) => void;
    uploadControls: ReturnType<typeof useFileUpload>;
    datasetControls: ReturnType<typeof useDataset>;
    optionsModal: ReturnType<typeof useDisclosure>;
    datasetsModal: ReturnType<typeof useDisclosure>;
    recentUploadsModal: ReturnType<typeof useDisclosure>;
    requestOption: IRequestOptionState;
    setOption: (option: Partial<IRequestOptionState>) => void;
    showDropZones: boolean;
    setChatMode: (mode: ChatModes) => void;
    chatMode: ChatModes;
}>({} as any);

export function useChatControls() {
    return React.useContext(ChatControlsContext);
}

export function ChatControlsProvider({
    children,
    id,
    chatItem,
    showDropZones,
}: {
    children: React.ReactNode;
    id: string | null;
    chatItem?: Chat;
    showDropZones: boolean;
}) {
    const [input, setInput] = useState<string>('');
    const { requestOption, setRequestOptionAction } = useRequestOptionStore();
    const uploadControls = useFileUpload();
    const datasetControls = useDataset();
    const optionsModal = useDisclosure();
    const recentUploadsModal = useDisclosure();
    const datasetsModal = useDisclosure();
    const [chatMode, setChatMode] = useState<ChatModes>(null);
    const router = useRouter();

    const setOption = (option: Partial<IRequestOptionState>) => {
        setRequestOptionAction({
            ...requestOption,
            ...option,
        });
    };
    useEffect(() => {
        if (id && chatItem) {
            setOption({
                retrievalDataset: chatItem.retrievalDataset,
                persona: chatItem.persona,
                modelName: chatItem.modelName,
            });
            uploadControls.clear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, !!chatItem]);
    useEffect(() => {
        if (requestOption.retrievalDataset && requestOption.useSearchEngine) {
            setOption({
                useSearchEngine: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOption.retrievalDataset]);

    const { mode } = router.query;
    useEffect(() => {
        // Reset the mode to null when the component is mounted and the route is /chat
        if (router.pathname === '/chat') {
            setChatMode(null);
        } else if (mode) {
            setChatMode(mode as 'pdf' | 'perception' | 'speech2speech' | 'agent');
        }
    }, [setChatMode, router.pathname, mode]);

    return (
        <ChatControlsContext.Provider
            value={{
                input,
                setInput,
                optionsModal,
                datasetsModal,
                recentUploadsModal,
                uploadControls,
                datasetControls,
                requestOption,
                setOption,
                showDropZones,
                setChatMode,
                chatMode,
            }}
        >
            {children}
        </ChatControlsContext.Provider>
    );
}

function useDataset() {
    const datasetRef = useRef<string | null>(null);
    const [datasets, setDatasets] = useState<string[]>([]);
    const [preparedMap, setPreparedMap] = useState<Record<string, boolean>>({});
    const [isFetching, setIsFetching] = useState(true);
    const [status, setStatus] = useState<'initial' | 'preupload' | 'uploaded' | 'completed' | 'preparing' | 'error'>(
        'initial',
    );
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [datasetName, setDatasetName] = useState<string | null>(null);
    const [error, setError] = useState<'unsupported' | 'retry' | null>(null);
    const { apiKey } = useAuthStore();

    useEffect(() => {
        async function fetchDatasets() {
            if (!apiKey) return;
            const reka = await rekaFactory(apiKey);
            const { data } = await reka.getDatasets();
            if (data) {
                setDatasets(data);
            }
            setIsFetching(false);
        }
        fetchDatasets();
    }, [apiKey]);

    const checkStatus = async (name: string) => {
        if (name in preparedMap) {
            return preparedMap[name];
        }
        const reka = await rekaFactory(apiKey);
        const { data } = await reka.getDataset(name);
        if (data) {
            setPreparedMap((v) => ({ ...v, [name]: data.is_retrieval_prepared }));
            return data.is_retrieval_prepared;
        }
        return null;
    };

    const fileSelected = (file: File) => {
        clear();
        setStatus('preupload');
        setFile(file);
    };

    const generateFilename = (file: File) => {
        let filename = file?.name;
        filename = filename.substring(0, filename.lastIndexOf('.')) || filename;
        filename = filename.slice(0, 62);
        let name = filename;
        let count = 0;
        while (datasets.includes(name)) {
            name = `${filename}-${++count}`;
        }
        return name;
    };

    const createDataset = async ({ file, name, description }: { file: File; name: string; description: string }) => {
        const reka = await rekaFactory(apiKey);
        setIsLoading(true);
        setError(null);
        const { data: datasetName, err } = await reka.uploadDataset({ file, name, description });
        if (!datasetName) {
            setError(err);
            throw err;
        }
        setStatus('uploaded');
        return prepareDataset(datasetName);
    };

    const prepareDataset = async (name: string) => {
        setIsLoading(true);
        setDatasetName(name);
        const reka = await rekaFactory(apiKey);
        datasetRef.current = name;
        const iterator = reka.prepareRetrieval(name);
        setStatus('preparing');
        for await (const response of iterator) {
            if (datasetRef.current !== name) return;
            if (response.status === 'ERROR') {
                setStatus('error');
                setIsLoading(false);
                return false;
            }
        }
        if (datasetRef.current !== name) return;
        setStatus('completed');
        setPreparedMap((v) => ({ ...v, [name]: true }));
        setIsLoading(false);
        datasetRef.current = null;
        return true;
    };

    const deleteDataset = async (name: string) => {
        const reka = await rekaFactory(apiKey);
        const { data, err } = await reka.deleteDataset(name);
        setDatasets((prev) => [...prev].filter((v) => v !== name));
        return !!err;
    };

    const clear = () => {
        if (status === 'completed') {
            setDatasets((items) => [datasetName!, ...items]);
        }
        setStatus('initial');
        setIsLoading(false);
        setError(null);
        setFile(null);
        setDatasetName(null);
        datasetRef.current = null;
    };
    return {
        preparedMap,
        prepareDataset,
        generateFilename,
        datasets,
        isFetching,
        status,
        isLoading,
        file,
        datasetName,
        error,
        fileSelected,
        createDataset,
        deleteDataset,
        clear,
        checkStatus,
    };
}

function useFileUpload() {
    const [file, setFile] = useState<File | null>(null);
    const [filename, setFilename] = useState<string | null>(null);
    const [objectUrl, setObjectUrl] = useState('');
    const [mediaUrl, setMediaUrl] = useState<string | null>(null);
    const [mediaType, setMediaType] = useState<MediaType | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<'unsupported' | 'retry' | 'size_limit' | 'duration_limit' | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const { apiKey } = useAuthStore();
    const { enabledFeatures } = useAppConfig();
    const ref = useRef('');
    const uploadMedia = async (file: File) => {
        clear();
        setFile(file);
        setFilename(file.name);
        const mediaType = getMediaType(file.name);
        if (!mediaType) {
            setErrorMsg('File not supported');
            return;
        }
        if (!enabledFeatures.audioUpload && mediaType === 'audio') {
            setErrorMsg('Audio not supported');
            return;
        }
        if (!enabledFeatures.videoUpload && mediaType === 'video') {
            setErrorMsg('Video not supported');
            return;
        }
        setMediaType(mediaType);
        const { message, err } = checkFileSize(file);
        if (err) {
            setErrorMsg(message);
            return;
        }
        const objectUrl = URL.createObjectURL(file);
        setObjectUrl(objectUrl);
        const fileUrl = await upload(file, 'media');
        if (fileUrl) {
            UrlObjectManager.registerObject(fileUrl, objectUrl);
        }
    };

    const uploadMediaStateless = async (file: File) => {
        const mediaType = getMediaType(file.name);
        if (!mediaType) {
            return { error: 'File not supported' };
        }
        if (mediaType === 'audio') {
            return { error: 'File not supported' };
        }
        const { message, err } = checkFileSize(file);
        if (err) {
            return { error: message };
        }
        const fileUrl = await upload(file, 'media');
        return { url: fileUrl, type: mediaType };
    };

    // only supports images
    const setMediaBase64 = async (file: File) => {
        clear();
        const mediaType = getMediaType(file.name);
        if (!mediaType) {
            setErrorMsg('File not supported');
            return;
        }
        if (mediaType !== 'image') {
            setErrorMsg('Only images supported');
            return;
        }
        const { message, err } = checkFileSize(file);
        if (err) {
            setErrorMsg(message);
            return;
        }
        const objectUrl = URL.createObjectURL(file);
        const base64 = await fileToBase64(file);
        if (!base64) {
            setErrorMsg('File not supported');
            return;
        }
        setMediaType(mediaType);
        setObjectUrl(objectUrl);
        setMediaUrl(base64);
    };

    const isValidMediaUrl = (url: string) => {
        return !!getMediaType(url);
    };
    const uploadMediaFromURl = (url: string) => {
        clear();
        setFilename(url.split('/')?.at(-1) || 'file_from_url');
        const mediaType = getMediaType(url);
        if (!mediaType) {
            setError('unsupported');
            setErrorMsg('Content not supported');
            return;
        }
        if (!enabledFeatures.audioUpload && mediaType === 'audio') {
            setError('unsupported');
            setErrorMsg('Audio not supported');
            return;
        }
        if (!enabledFeatures.videoUpload && mediaType === 'video') {
            setError('unsupported');
            setErrorMsg('Video not supported');
            return;
        }
        setMediaType(mediaType);
        setError(null);
        setErrorMsg(null);
        setMediaUrl(url);
    };
    const uploadFile = async (file: File) => {
        clear();
        setFile(file);
        setFilename(file.name);
        await upload(file, 'file');
    };
    const upload = async (file: File, mode: 'media' | 'file') => {
        const jobId = String(Math.random());
        const reka = await rekaFactory(apiKey);
        setIsLoading(true);
        setError(null);
        setErrorMsg(null);
        ref.current = jobId;
        const { data, err } = await (mode === 'media' ? reka.uploadMedia({ file }) : reka.uploadFile({ file }));
        if (ref.current !== jobId) return;
        if (!data) {
            setError(err);
        } else if (mode === 'media') {
            setMediaUrl(data);
        } else if (mode === 'file') {
            setFileUrl(data);
        }
        setIsLoading(false);
        return data;
    };

    const useExisting = ({ url, filename, type }: UploadedFile) => {
        clear();
        setFilename(filename);
        if (type === 'code_file') {
            setFileUrl(url);
        } else {
            setMediaUrl(url);
            setMediaType(type);
        }
    };

    const useCustomUrl = (url: string) => {
        clear();
        setMediaUrl(url);
        setMediaType('custom');
    };
    const clear = () => {
        setIsLoading(false);
        setObjectUrl('');
        setMediaUrl(null);
        setMediaType(null);
        setFileUrl(null);
        setFile(null);
        setFilename(null);
        setError(null);
        setErrorMsg(null);
        ref.current = '';
    };
    return {
        existingFile: !!mediaUrl || !!fileUrl,
        file,
        filename,
        setFile,
        objectUrl,
        mediaType,
        mediaUrl,
        fileUrl,
        error,
        errorMsg,
        isLoading,
        uploadMedia,
        uploadMediaStateless,
        setMediaBase64,
        reUploadMedia: () => {
            if (!file) return;
            upload(file, 'media');
        },
        uploadFile,
        reUploadFile: () => {
            if (!file) return;
            upload(file, 'file');
        },
        clear,
        useExisting,
        useCustomUrl,
        setError,
        setErrorMsg,
        uploadMediaFromURl,
        isValidMediaUrl,
    };
}

const MEDIA_TYPES = ['audio', 'image', 'video', 'application', 'custom'] as const;
type MediaType = (typeof MEDIA_TYPES)[number];

export function getMediaType(filename: string) {
    const mimetype = mime.lookup(filename);
    if (!mimetype) return null;
    const mediaType = mimetype.split('/')[0] as MediaType;
    if (MEDIA_TYPES.includes(mediaType)) return mediaType;
    return null;
}

export function getFileType(file: File) {
    const mediaType = file.type.split('/')[0] as MediaType;
    if (MEDIA_TYPES.includes(mediaType)) return mediaType;
    return null;
}

const MB = 1024 * 1024;

const SIZE_LIMITS = {
    audio: 12 * MB,
    video: 64 * MB,
    image: 12 * MB,
    application: 64 * MB,
    custom: 64 * MB,
};

const LIMIT_COPY = {
    audio: 'Too large, limit of 12MB',
    video: 'Too large, limit of 64MB',
    image: 'Too large, limit of 12MB',
    application: 'Too large, limit of 64MB',
    custom: 'Too large, limit of 64MB',
};

export function checkFileSize(file: File) {
    const mediaType = getMediaType(file.name);
    if (!mediaType) return { message: 'Unsupported media type' as const, err: true };
    if (file.size > SIZE_LIMITS[mediaType]) return { message: LIMIT_COPY[mediaType], err: true };
    return { message: null, err: false };
}

export function getModelSuffix(name: string) {
    if (!name || name.includes('default') || name.includes('flash')) {
        return 'Flash';
    }
    if (name.includes('core')) {
        return 'Core';
    }
    if (name.includes('lily')) {
        return 'Core';
    }
    if (name.includes('edge')) {
        return 'Edge';
    }
    return '';
}

function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            try {
                // @ts-ignore
                resolve(reader.result);
            } catch (e) {
                reject(e);
            }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
}
