import cx from 'classnames';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';
import React, { useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsCheckCircle, BsClipboard } from 'react-icons/bs';
import { BsFiletypeJson } from 'react-icons/bs';
import { RiPlayFill } from 'react-icons/ri';

import { Box, Code, Flex, IconButton, Spinner, Text } from '@chakra-ui/react';
import { CodeInterpreter } from '@e2b/code-interpreter';

// import { captureException } from '@sentry/nextjs';

const CODE_INTERPRETER_API_KEY = process.env.NEXT_PUBLIC_E2B_API_KEY;
export function CodeRenderer({
    message,
    isLoading,
    onCopy,
    onExec,
    innerClassname,
}: {
    message?: string;
    isLoading?: boolean;
    onCopy?: (code: string) => void;
    onExec?: (result: any) => void;
    innerClassname?: string;
}) {
    const [copied, setCopied] = useState(false);
    const ref = useRef<number>(null);
    const lines = message?.split('\n');
    let insideCodeBlock = false;
    let code = '';
    let language: string;

    const animateCopied = (code: string) => () => {
        setCopied(true);
        if (ref.current !== null) {
            clearTimeout(ref.current);
        }
        // @ts-ignore
        ref.current = setTimeout(() => {
            setCopied(false);
            // @ts-ignore
            ref.current = null;
        }, 1500);
        if (onCopy) onCopy(code);
    };

    const isEmptyJsonCodeBlock = message?.trim() === '```json\n{}\n```';

    return (
        <Box width={'100%'} height={'100%'}>
            {lines?.map((line, index) => {
                if (line.startsWith('```')) {
                    insideCodeBlock = !insideCodeBlock;
                    if (insideCodeBlock) {
                        language = line?.slice(3) || '';
                        code = '';
                        return null;
                    } else {
                        let highlightedCode = code;
                        try {
                            if (language) {
                                highlightedCode = hljs?.highlight(code, { language }, true).value;
                            }
                        } catch (e) {
                            // This logs too much find a way to sample this instead
                            // captureException(e); // Logs the error
                        }
                        return (
                            <Flex
                                key={index}
                                w={'100%'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                borderColor={'background-secondary'}
                                // bg={'background-secondary'}
                                backgroundColor={'#1A1333'}
                                minHeight={'400px'}
                                height={'100%'}
                            >
                                <Box
                                    px={'16px'}
                                    py={'4px'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    backgroundColor={'#0F0A20'}
                                    width={'100%'}
                                >
                                    <Text
                                        textStyle={'body-12'}
                                        fontSize={'14px'}
                                        lineHeight={'26px'}
                                        whiteSpace="pre-line"
                                        color={'#D1C4DF'}
                                    >
                                        {language}
                                    </Text>
                                    {!isLoading ? (
                                        <Flex>
                                            <CopyToClipboard text={code} onCopy={animateCopied(code)}>
                                                <IconButton
                                                    border={'none'}
                                                    aria-label={'copy code'}
                                                    size={'xs'}
                                                    color={'#D1C4DF'}
                                                    icon={copied ? <BsCheckCircle /> : <BsClipboard />}
                                                ></IconButton>
                                            </CopyToClipboard>
                                        </Flex>
                                    ) : (
                                        <Spinner size={'sm'} />
                                    )}
                                </Box>
                                {!message || message.trim() === '' || isEmptyJsonCodeBlock ? (
                                    <Flex
                                        flexDirection={'column'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        height="100%"
                                    >
                                        {isLoading ? (
                                            <Spinner size={'lg'} color={'#8674ca'} />
                                        ) : (
                                            <Box
                                                backgroundColor={'#8674ca'}
                                                width={'fit-content'}
                                                padding={'10px'}
                                                borderRadius={'3px'}
                                            >
                                                <BsFiletypeJson size={50} color="#E1E6F2" />
                                            </Box>
                                        )}
                                        <Text
                                            textAlign={'center'}
                                            marginTop={'35px'}
                                            color={'#CCD3E1'}
                                            fontSize={'1em'}
                                            width={'75%'}
                                        >
                                            Output in .JSON will appear here after you upload your file.
                                        </Text>
                                    </Flex>
                                ) : (
                                    <Code
                                        backgroundColor={'#0F0A20'}
                                        whiteSpace="pre-wrap"
                                        fontSize="sm"
                                        // bg={'background-main'}
                                        color={'text-primary'}
                                        p={4}
                                        px={8}
                                        w={'100%'}
                                        height={'100%'}
                                        borderBottomRadius={'md'}
                                        className={cx(`language-${language}`, innerClassname)}
                                        dangerouslySetInnerHTML={{ __html: highlightedCode }}
                                    />
                                )}
                            </Flex>
                        );
                    }
                } else if (insideCodeBlock) {
                    code += line + '\n';
                    return null;
                } else {
                    return (
                        <Text
                            textStyle={'body-18'}
                            color={'text-primary'}
                            lineHeight={'26px'}
                            whiteSpace="pre-line"
                            mb={'10px'}
                            key={index}
                        >
                            {line}
                            {isLoading && index === lines.length - 1 && (
                                <Text className="cursor" fontWeight={'bold'}>
                                    |
                                </Text>
                            )}
                        </Text>
                    );
                }
            })}
        </Box>
    );
}
